<template>
	<div class="achievementConfirm">
		<el-form
			:model="searchForm"
			inline
			ref="form"
			class="searchForm"
			size="small"
			style="background-color: #fff; margin-bottom: 5px; padding-top: 20px"
		>
			<el-form-item label="姓名">
				<el-input v-model="searchForm.staffName" placeholder="输入姓名" style="width: 160px" clearable />
			</el-form-item>
			<el-form-item label="部门">
				<treeselect
					:props="depProps"
					:options="deps"
					:value="depsId"
					:clearable="true"
					:accordion="true"
					:level="depsId"
					@getValue="_getValue($event)"
					style="width: 160px"
				/>
			</el-form-item>
			<el-form-item label="工号">
				<el-input v-model="searchForm.jobNumber" clearable placeholder="输入工号" style="width: 160px" />
			</el-form-item>
			<el-form-item label="手机号">
				<el-input
					v-model="searchForm.phoneNumber"
					maxlength="11"
					onkeyup="value=value.replace(/[^\d]/g,'')"
					clearable
					placeholder="输入手机号"
					style="width: 160px"
				/>
			</el-form-item>
			<el-form-item label="绩效等级">
				<el-select v-model="searchForm.performanceScoreStaff" filterable placeholder="选择绩效等级" style="width: 160px" clearable>
					<el-option v-for="item in optionsScore" :key="item.value" :label="item.value" :value="item.key"> </el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="绩效考核名称">
				<el-select v-model="searchForm.performanceId" filterable placeholder="请选择" style="width: 160px" clearable>
					<el-option v-for="item in options" :key="item.value" :label="item.value" :value="item.key"> </el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="状态">
				<el-select v-model="searchForm.status" clearable placeholder="选择绩效状态" style="width: 160px">
					<el-option v-for="(item, i) in assessAffirmStatusList" :key="i" :value="item.dictId" :label="item.dictName"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="起止时间">
				<el-date-picker v-model="searchForm.startTime" type="date" value-format="yyyy-MM-dd [00:00:00]" format="yyyy-MM-dd" clearable>
				</el-date-picker>
				<span style="margin: 0 5px">-</span>
				<el-date-picker v-model="searchForm.endTime" type="date" value-format="yyyy-MM-dd [23:59:59]" format="yyyy-MM-dd" clearable>
				</el-date-picker>
			</el-form-item>
			<el-button type="primary" size="small" @click="_getList(1)" icon="el-icon-search">查询</el-button>
			<div class="fr">
				<el-button
					type="primary"
					size="small"
					icon="el-icon-upload2"
					@click="dialogImportVisible = true"
					v-if="staffIdStatus != null && permissionControlBtns(pageName, 'Import')"
					>导入</el-button
				>
				<el-button
					type="primary"
					size="small"
					icon="el-icon-download"
					@click="_export"
					v-if="staffIdStatus != null && permissionControlBtns(pageName, 'Export')"
					>导出</el-button
				>
			</div>
		</el-form>
		<div style="background-color: #fff; padding-bottom: 20px">
			<el-table :data="list" stripe :header-cell-style="tableHeaderColor">
				<el-table-column fixed type="index" style="margin-top: 10px" align="center" label="序号" width="80"> </el-table-column>
				<el-table-column
					v-for="(col, i) in columns"
					:prop="col.prop"
					:key="i"
					:align="i == 0 ? 'left' : 'center'"
					:label="col.label"
					:width="col.width"
					show-overflow-tooltip
				>
					<template v-slot="{ row }" v-if="col.prop == 'status'">
						<el-link :underline="false" style="cursor: auto" :type="_returnStatusColor(row.approveStatus)">{{ row.status }}</el-link>
					</template>
					<template v-slot="{ row }" v-else-if="col.prop == 'createtime'">
						{{ row.createtime.slice(0, 10) }}
					</template>
				</el-table-column>
				<el-table-column fixed="right" align="center" label="操作" width="100px">
					<template v-slot="{ row }">
						<el-button type="text" @click.native.stop="_toDetail(row)">详情</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				v-if="searchForm.totalNum > 0"
				@size-change="_handleSizeChange"
				@current-change="_handleCurrentChange"
				:current-page="searchForm.pageNo"
				:page-sizes="[20, 40, 80, 100]"
				:page-size="searchForm.pageSize"
				style="text-align: right; margin-top: 10px"
				layout="total, sizes, prev, pager, next, jumper"
				:total="searchForm.totalNum"
			>
			</el-pagination>
		</div>
		<ImportCom
			:dialogImportVisible.sync="dialogImportVisible"
			:importUrl="importUrl"
			:type="type"
			:params="searchForm"
			@handleSearch="_getList(searchForm.pageNo)"
		/>
	</div>
</template>
<script>
import treeselect from '@/components/treeSelect/treeSelect';
import ImportCom from '@/components/import';
import { performanceAssessAffirmList, performanceNameChoice, performanceScoreList } from '@/api/performance';
export default {
	props: {
		pageName: {}
	},
	components: { treeselect, ImportCom },
	data() {
		return {
			searchForm: {
				staffName: '',
				orgId: '',
				jobNumber: '',
				phoneNumber: '',
				performanceId: '',
				status: '',
				performanceScoreStaff: '',
				startTime: '',
				endTime: '',
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			columns: [
				{ label: '绩效考核名称', prop: 'performanceName', width: 160 },
				{ label: '姓名', prop: 'staffName' },
				{ label: '工号', prop: 'jobNumber' },
				{ label: '手机号码', prop: 'phoneNumber', width: 120 },
				{ label: '部门', prop: 'orgName' },
				{ label: '职位', prop: 'officeName' },
				{ label: '状态', prop: 'status' },
				{ label: '绩效', prop: 'performanceScoreUser' },
				{ label: '生成时间', prop: 'createtime' }
			],
			deps: this.$store.state.setData.depsList,
			depProps: {
				value: 'id',
				label: 'orgName',
				children: 'children'
			},
			depsId: '',
			list: [],
			assessAffirmStatusList: [],
			options: [],
			optionsScore: [],
			dialogImportVisible: false,
			importUrl: '/v1/performance/assess/affirm/import',
			type: 'performanceAssess',
			dateRange: '',
			staffIdStatus: this.$store.state.app.staffId
		};
	},
	methods: {
		_dateRangeChange(val) {
			if (val) {
				this.searchForm.startTime = val[0];
				this.searchForm.endTime = val[1];
			} else {
				this._clear();
			}
		},
		_clear() {
			this.dateRange = '';
			this.searchForm.startTime = '';
			this.searchForm.endTime = '';
		},
		_getValue(eve) {
			if (eve) {
				this.depsId = eve.id;
				this.searchForm.orgId = eve.id;
			} else {
				this.depsId = '';
				this.searchForm.orgId = '';
			}
		},
		_getList(page) {
			page ? (this.searchForm.pageNo = page) : '';
			this.loading = true;
			performanceAssessAffirmList(this.searchForm).then((res) => {
				this.list = res.list;
				this.searchForm.totalNum = res.totalNum;
				this.loading = false;
			});
		},
		_handleSizeChange(val) {
			this.searchForm.pageSize = val;
			this._getList();
		},
		_handleCurrentChange(val) {
			this.searchForm.pageNo = val;
			this._getList();
		},
		_returnStatusColor(status) {
			switch (Number(status)) {
				case 9:
					return 'danger';
				case 10:
					return 'primary';
				default:
					return 'success';
			}
		},
		_toDetail(row) {
			this.$router.push({
				name: 'AchievementsConfirmDetail',
				query: { id: row.id, staffid: row.staffid }
			});
		},
		_export() {
			const outStr = encodeURIComponent(JSON.stringify(this.searchForm));

			const url = `/v1/performance/assess/affirm/export?params=${outStr}`;

			window.open(document.location.origin + url);
		},
		_getperformanceNameChoice() {
			performanceNameChoice({}).then((res) => {
				this.options = res.list;
			});
		},
		_getperformanceScoreList() {
			performanceScoreList({ screenType: 2 }).then((res) => {
				this.optionsScore = res.list;
			});
		},
		_updatePane() {
			this._getperformanceNameChoice();
			this._getperformanceScoreList();
			this._getList(this.searchForm.pageNo);
		},
		tableHeaderColor({ rowIndex }) {
			if (rowIndex === 0) {
				return 'background-color: #F0F8FF;';
			}
		}
	},
	mounted() {
		this._getperformanceNameChoice();
		this._getperformanceScoreList();
		this._getList(1);
		const mapArr = ['assessAffirmStatus'];

		mapArr.forEach((val) => {
			this[`${val}List`] = this.$store.state.app.dict.filter((item) => item.groupId == val);
		});
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
</style>
<style lang="scss">
@media only screen and (max-width: 1536px) {
	.achievementConfirm .el-table {
		margin-top: 50px;
	}
}
@media only screen and (max-width: 1323px) {
	.achievementConfirm .el-table {
		margin-top: 0px;
	}
}
</style>
