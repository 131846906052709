<template>
	<section class="achievementsmanagement">
		<el-tabs v-model="activeName" class="app-container">
			<el-tab-pane label="绩效考核" name="1" lazy v-if="permissionControl('PerformanceAppraisal')"
				><Assessment ref="pane1" pageName="PerformanceAppraisal"
			/></el-tab-pane>
			<el-tab-pane label="绩效等级" name="2" lazy v-if="permissionControl('PerformanceRating')"
				><Level ref="pane2" pageName="PerformanceRating"
			/></el-tab-pane>
			<el-tab-pane label="绩效确认" name="3" lazy v-if="permissionControl('PerformanceConfirmation')"
				><Confirm ref="pane3" pageName="PerformanceConfirmation"
			/></el-tab-pane>
		</el-tabs>
	</section>
</template>
<script>
import Assessment from './assessment';
import Level from './level';
import Confirm from './confirm';
export default {
	components: { Assessment, Level, Confirm },
	data() {
		return {
			activeName: '1',
			searchForm: {}
		};
	},
	mounted() {
		const data = JSON.parse(sessionStorage.getItem(`${this.$route.name}Data`));

		if (data?.activeName) {
			this.activeName = data.activeName;
			if (data.status) {
				this.$refs.pane1.status = data.status;
			}
			if (data.searchForm) {
				this.searchForm = data.searchForm;
			}
		}
		// 设置tab默认
		this.setActiveTab(this.$route.name, [
			{ name: '1', key: 'PerformanceAppraisal' },
			{ name: '2', key: 'PerformanceRating' },
			{ name: '3', key: 'PerformanceConfirmation' }
		]);
	},
	watch: {
		activeName(val) {
			const _pane = String(`pane${val}`);

			// eslint-disable-next-line no-unused-expressions
			this.$refs[_pane] && this.$refs[_pane]._updatePane();
		}
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			`${this.$route.name}Data`,
			JSON.stringify({
				activeName: this.activeName,
				status: this.$refs.pane1?.status,
				searchForm:
					// eslint-disable-next-line no-nested-ternary
					this.activeName == '1'
						? this.$refs.pane1?.searchForm
						: this.activeName == '2'
						? this.$refs.pane2?.searchForm
						: this.$refs.pane3?.searchForm
			})
		);
		next();
	}
};
</script>
<style lang="scss">
.achievementsmanagement {
	.el-tabs__header {
		margin-bottom: 5px;
	}
}
</style>
