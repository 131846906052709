import { render, staticRenderFns } from "./confirm.vue?vue&type=template&id=580d97fa&scoped=true&"
import script from "./confirm.vue?vue&type=script&lang=js&"
export * from "./confirm.vue?vue&type=script&lang=js&"
import style0 from "./confirm.vue?vue&type=style&index=0&id=580d97fa&prod&lang=scss&scoped=true&"
import style1 from "./confirm.vue?vue&type=style&index=1&id=580d97fa&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "580d97fa",
  null
  
)

export default component.exports