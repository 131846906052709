<template>
	<div v-loading="loading">
		<el-form style="padding: 20px 0 5px" :model="searchForm" inline ref="form" class="searchForm" size="small">
			<el-form-item label="绩效考核名称">
				<el-select v-model="searchForm.performanceId" filterable placeholder="请选择" style="width: 160px" clearable>
					<el-option v-for="item in options" :key="item.value" :label="item.value" :value="item.key"> </el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="年份">
				<el-date-picker
					type="year"
					v-model="searchForm.year"
					value-format="yyyy"
					format="yyyy"
					placeholder="选择年份"
					style="width: 160px"
					clearable
				>
				</el-date-picker>
			</el-form-item>
			<el-button type="primary" size="small" style="margin-left: 5px" icon="el-icon-search" @click="_getList(1)">查询</el-button>
			<el-button
				type="primary"
				size="small"
				style="margin-left: 15px"
				icon="el-icon-setting"
				@click="_handleToPage('AchievementsSettingIndex')"
				v-if="permissionControlBtns(pageName, 'Set')"
				>设置</el-button
			>
			<el-button
				type="primary"
				class="fr"
				icon="el-icon-plus"
				@click="_handleToPage('AchievementsCreate')"
				size="small"
				v-if="staffIdStatus != null && permissionControlBtns(pageName, 'Add')"
				>新建绩效</el-button
			>
		</el-form>
		<el-row style="margin-bottom: 20px">
			<el-tabs v-model="status" @tab-click="_tabClick">
				<el-tab-pane :label="`进行中（${statistics.underway || 0}）`" name="0"></el-tab-pane>
				<el-tab-pane :label="`未开始（${statistics.notStarted || 0}）`" name="1"></el-tab-pane>
				<el-tab-pane :label="`绩效计划中（${statistics.inThePlan || 0}）`" name="2"></el-tab-pane>
				<el-tab-pane :label="`绩效考评中（${statistics.evaluation || 0}）`" name="3"></el-tab-pane>
				<el-tab-pane :label="`结果确认中（${statistics.confirmation || 0}）`" name="4"></el-tab-pane>
				<el-tab-pane :label="`绩效已公布（${statistics.publish || 0}）`" name="5"></el-tab-pane>
			</el-tabs>
		</el-row>
		<div>
			<el-card class="box-card" v-for="(item, i) in list" :key="i" shadow="never">
				<div class="box-card-top">
					{{ item.performanceName }} <span>操作人：{{ item.createName }}</span>
				</div>
				<div class="box-card-line"></div>
				<el-row style="text-align: right; margin-bottom: 15px">
					<el-button
						type="primary"
						size="small"
						v-if="
							staffIdStatus != null &&
							item.startStatus != 2 &&
							item.startStatus == 0 &&
							status != 5 &&
							status != 0 &&
							item.dataStatus != 0
						"
						@click="_switch(item, 1, 2)"
						>开启计划</el-button
					>
					<el-button
						type="primary"
						size="small"
						v-if="staffIdStatus != null && item.startStatus != 2 && item.startStatus == 1 && status != 1"
						@click="_switch(item, 0, 2)"
						>关闭计划</el-button
					>
					<el-button
						type="primary"
						size="small"
						v-if="staffIdStatus != null && item.startStatus != 2 && item.selfEvaluationStatus == 0 && status != 1 && status != 5"
						@click="_switch(item, 1, 3)"
						>开始自评</el-button
					>
					<el-button
						type="primary"
						size="small"
						v-if="staffIdStatus != null && item.startStatus != 2 && item.selfEvaluationStatus == 1 && status != 1 && status != 5"
						@click="_switch(item, 0, 3)"
						>关闭自评</el-button
					>
					<el-dropdown style="margin-left: 10px">
						<el-button type="primary" size="small">操作</el-button>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item
								v-for="(child, index) in commands"
								@click.native.stop="_handleRowClick(item, child, i)"
								:command="child"
								:key="index"
								v-show="_returnBtnStatus(item, child)"
								>{{ child.label }}</el-dropdown-item
							>
						</el-dropdown-menu>
					</el-dropdown>
				</el-row>
				<el-table :data="[item]" stripe :header-cell-style="tableHeaderColor" :row-style="tableRowStyle" @row-click="_handleTableRowClick">
					<el-table-column prop="assessCycle" label="绩效周期" align="center" show-overflow-tooltip min-width="200"> </el-table-column>
					<el-table-column align="center" label="考核范围" prop="assessRange" show-overflow-tooltip min-width="150">
						<!--<template slot-scope="{row}">
                        <div style="max-height: 90px; word-break: break-word; overflow: hidden; text-overflow: ellipsis;">
                            {{row.assessRange.length>36?row.assessRange.substring(0,34)+'...':row.assessRange}}
                        </div>
                    </template>!-->
					</el-table-column>
					<el-table-column prop="assessTemplateName" label="绩效考核模板" align="center" min-width="150"> </el-table-column>
					<el-table-column v-for="(col, i) in columns" :prop="col.prop" :key="i" align="center" :label="col.label" show-overflow-tooltip>
						<template v-slot="{ row }">
							<span :style="{ color: row[col.prop] <= 0 ? '#FF604D' : '#484848' }">{{ row[col.prop] }}人</span>
						</template>
					</el-table-column>
				</el-table>
			</el-card>
			<el-pagination
				v-if="searchForm.totalNum > 0"
				@size-change="_handleSizeChange"
				@current-change="_handleCurrentChange"
				:current-page="searchForm.pageNo"
				:page-sizes="[20, 40, 80, 100]"
				:page-size="searchForm.pageSize"
				style="text-align: right; background-color: #fff; padding: 20px 0"
				layout="total, sizes, prev, pager, next, jumper"
				:total="searchForm.totalNum"
			>
			</el-pagination>
			<NoneCom v-else />
		</div>
	</div>
</template>
<script>
import {
	performanceNameChoice,
	performanceAssessList,
	performanceAssessSwitch,
	performanceDelete,
	performanceNumberStatistics
} from '@/api/performance';
import NoneCom from './components/NoneCom';
export default {
	props: { pageName: {} },
	components: { NoneCom },
	data() {
		return {
			searchForm: {
				performanceId: '',
				year: '',
				status: '0',
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			status: this.cstatus || '0',
			list: [],
			commands: [
				{ label: '详情', val: 1 },
				{ label: '编辑', val: 2 },
				{ label: '副本', val: 3 },
				{ label: '删除', val: 4 }
			],
			typeStatus: [
				{ label: '进行中', type: 0, val: 'underway' },
				{ label: '未开始', type: 1, val: 'notStarted' },
				{ label: '绩效计划中', type: 2, val: 'inThePlan' },
				{ label: '绩效考评中', type: 3, val: 'evaluation' },
				{ label: '结果确认中', type: 4, val: 'confirmation' },
				{ label: '绩效已公布', type: 5, val: 'publish' }
			],
			columns: [
				{ label: '考核人数', prop: 'assessNumber' },
				{ label: '待填写', prop: 'notWriteNumber' },
				{ label: '待审核', prop: 'notAuditNumber' },
				{ label: '待自评', prop: 'notSelfNssessmentNumber' },
				{ label: '待评定', prop: 'notAssessNumber' },
				{ label: '待确认', prop: 'notAffirmNumber' },
				{ label: '已公布', prop: 'publishNumber' }
			],
			options: [],
			statistics: {},
			loading: false,
			staffIdStatus: this.$store.state.app.staffId
		};
	},
	methods: {
		_handleToPage(name) {
			this.$router.push({ name });
		},
		_getList(page) {
			this.loading = true;
			page ? (this.searchForm.pageNo = page) : '';
			performanceAssessList(this.searchForm).then((res) => {
				this.list = res.list;
				this.searchForm.totalNum = res.totalNum;
				this.loading = false;
			});
		},
		_handleSizeChange(val) {
			this.searchForm.pageSize = val;
			this._getList();
		},
		_handleCurrentChange(val) {
			this.searchForm.pageNo = val;
			this._getList();
		},
		_getStatistics() {
			performanceNumberStatistics({}).then((res) => {
				this.statistics = res;
				this.$forceUpdate();
			});
		},
		_deleteItem(item, index) {
			this.$confirm('您确认要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.loading = true;
					performanceDelete({ id: item.id }).then((res) => {
						this.loading = false;
						if (res.code == 0) {
							this.list.splice(index, 1);
							this._getList(this.searchForm.pageNo);
							this._getStatistics();
							this._getperformanceNameChoice();
						}
					});
				})
				.catch(() => {});
		},
		_switch(item, status, type) {
			const params = {
				performanceId: item.id,
				switchType: type,
				switchStatus: status
			};

			this.$confirm(`您确认要${status == 1 ? '开启' : '关闭'}${item.performanceName}${type == 2 ? '计划' : '自评'}？`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.loading = true;
					performanceAssessSwitch(params).then((res) => {
						if (res.code == 0) {
							if (status == 1) {
								if (type == 2) {
									item.startStatus = 1;
									this.$message.success('开启计划成功');
								} else {
									item.selfEvaluationStatus = 1;
									this.$message.success('开启自评成功');
								}
							} else if (type == 2) {
								item.startStatus = 2;
								this.$message.success('关闭计划成功');
							} else {
								item.selfEvaluationStatus = 2;
								this.$message.success('关闭自评成功');
							}
							this._getList(this.searchForm.pageNo);
							this._getStatistics();
							this._getperformanceNameChoice();
						}
						this.loading = false;
					});
				})
				.catch(() => {});
		},
		_returnBtnStatus(item, row) {
			if (this.staffIdStatus == null && row.val != 1) {
				return false;
			}
			if (row.val == 2 || row.val == 4) {
				if (row.val == 4) {
					if (item.startStatus == 2 || item.processStatus == 1) {
						return true;
					}
					return false;
				}
				if (item.startStatus != 2 && (item.dataStatus == 0 || Number(item.processStatus) < 3)) {
					return true;
				}
				return false;
			}
			return true;
		},
		_handleTableRowClick(row) {
			this.$router.push({
				name: 'AchievementsDetail',
				query: { assessmentid: row.id }
			});
		},
		_handleRowClick(item, row, index) {
			switch (row.val) {
				case 1:
					this.$router.push({
						name: 'AchievementsCreate',
						query: { assessmentid: item.id, readonly: 1 }
					});
					break;
				case 2:
					this.$router.push({
						name: 'AchievementsCreate',
						query: { assessmentid: item.id }
					});
					break;
				case 3:
					this.$router.push({
						name: 'AchievementsCreate',
						query: { assessmentid: item.id, copy: 1 }
					});
					break;

				default:
					this._deleteItem(item, index);
					break;
			}
		},
		_tabClick(tab) {
			this.status = tab.name;
		},
		_getperformanceNameChoice() {
			performanceNameChoice({}).then((res) => {
				this.options = res.list;
			});
		},
		_updatePane(val) {
			this._getperformanceNameChoice();
			this._getStatistics();
			this._getList(val || this.searchForm.pageNo);
		},
		tableHeaderColor({ rowIndex }) {
			if (rowIndex === 0) {
				return 'background-color: #F0F8FF;';
			}
		},
		tableRowStyle() {
			return 'cursor: pointer;';
		}
	},
	mounted() {
		this._getperformanceNameChoice();
		this._getList();
		this._getStatistics();
	},
	watch: {
		status(val) {
			this.searchForm.status = val;
			this._updatePane(1);
		}
	}
};
</script>
<style lang="scss" scoped>
.box-card {
	margin-bottom: 20px;
	.box-card-top {
		line-height: 25px;
		font-family: PingFangSC-Semibold;
		font-size: 18px;
		color: #2b3642;
		letter-spacing: 0;
		span {
			font-family: PingFangSC-Regular;
			font-size: 14px;
			color: #2b3642;
			margin-left: 27px;
		}
		&:before {
			display: inline-block;
			width: 8px;
			height: 22px;
			background: #498df0;
			content: ' ';
			border-radius: 5px;
			transform: translateY(-1px);
			vertical-align: middle;
			margin-right: 8px;
		}
	}
	.box-card-line {
		height: 1px;
		background: #eaedf1;
		margin: 26px 0 20px 0;
	}
	.box-card-header {
		margin-bottom: 10px;
		.box-card-header-templateName {
			line-height: 32px;
		}
	}
	.box-card-footer-box {
		.el-row {
			background-color: #ebebeb;
			p {
				padding: 8px 4px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.box-card-footer-box-right {
				text-align: center;
			}
		}
	}
}
::v-deep.el-form-item__label {
	font-weight: normal;
}
</style>
